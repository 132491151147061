import React from 'react';


import { Swiper, SwiperSlide } from 'swiper/react';



import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import {  Autoplay } from 'swiper/modules';



import partner1 from "../Img/logo collections/logo1.png"
import partner2 from "../Img/logo collections/logo2.png"
import partner3 from "../Img/logo collections/logo3.jpg"
import partner4 from "../Img/logo collections/logo4.png"
import partner5 from "../Img/logo collections/logo5.png"
import partner6 from "../Img/logo collections/logo6.png"
import partner7 from "../Img/logo collections/logo7.png"
import partner8 from "../Img/logo collections/logo8.png"
import partner9 from "../Img/logo collections/logo9.png"
import partner10 from "../Img/logo collections/logo10.png"
import partner11 from "../Img/logo collections/logo11.jpg"
import partner12 from "../Img/logo collections/logo12.png"
import partner13 from "../Img/logo collections/logo13.png"
import partner14 from "../Img/logo collections/logo14.png"
import partner15 from "../Img/logo collections/logo15.jpg"
import partner16 from "../Img/logo collections/logo16.jpg"
import partner17 from "../Img/logo collections/logo17.jpg"
import partner18 from "../Img/logo collections/logo18.png"
import partner19 from "../Img/logo collections/logo19.png"
import partner20 from "../Img/logo collections/logo20.jpg"
import partner21 from "../Img/logo collections/logo21.jpg"



const BrandScroller = () => {

 


  return (
   
    <>
    <div className='brandsicon'>
    <div className='text-center text-white mt-5 ' >
         <h1 style={{fontWeight:"700"}}>Our Clients</h1>

    </div>
      <Swiper 

breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 35,
          },
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        
        
        modules={[Autoplay]}
        className="mySwiper"
      >
      
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner1} alt="" />
        </div>
             </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner2} alt="" />
        </div>
             </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner3} alt="" />
        </div>
             </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner4} alt="" />
        </div>
             </div>
        </SwiperSlide>

          <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner5} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner6} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner7} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner8} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner9} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner10} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner11} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2  bg-white' >
        <img  src={partner12} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner13} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner14} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner15} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner16} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner17} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-dark ' >
        <img  src={partner18} alt="" />
        </div>
             </div>
        </SwiperSlide>  
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-dark ' >
        <img  src={partner19} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner20} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={partner21} alt="" />
        </div>
             </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="brand-list p-1 my-5">
        <div className='brand-card shadow rounded p-2 bg-white ' >
        <img  src={ "https://www.jllhomes.co.in/themes/custom/olm_ip/logo.svg"} alt="" />
        </div>
             </div>
        </SwiperSlide>

      </Swiper>
      </div>
    </>
  );
};

export default BrandScroller;
